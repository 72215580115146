<template>
  <div class="foot">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="12" :md="14" :lg="12" >承办单位：云南省大学科技园 Copyright © 2022 云南省大学科技园 All Right Reserved</el-col>
      <el-col :sm="12" :md="8" :lg="8" style="text-align:right">网站备案: 滇ICP备 13004217号-1111</el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>