<template>
  <div class="ptb16">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="12" :md="11" :lg="10">
        <a href="/"><img src="@/assets/images/logo.jpg" /></a>
      </el-col>
      <el-col :sm="12" :md="11" :lg="10"> 
        <div class="head_txt">
          <span v-if="!isUserLogin">       
            <router-link to="/login" >登录</router-link>
            |
            <router-link to="/regist">注册</router-link>
          </span>
          <span v-else>
            欢迎，<span class="blue">{{uname}}</span> 光临！ |
            <router-link to="/member" target="_blank">我的管理</router-link>
            |          
            <a @click="logout" class="logout">安全退出</a>
          </span>
          <!-- <a href="/" style="padding-left: 30px;"><img src="@/assets/images/wx.jpg" /></a>         -->
        </div>
        <div class="sou_box">
          <div class="sou">
            <el-input v-model="keySearch" placeholder="请输入关键词搜索" prefix-icon="Search" clearable size="large" >
              <template #prepend>
                <el-select v-model="selectSearch" placeholder="选择" size="large" style="width: 100px">
                  <el-option label="资源" value="1" />
                  <el-option label="文章" value="2" />                
                </el-select>
              </template>
              <template #append>
                <el-button type="success" @click="search">搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <div class="menu">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <ul>        
          <li v-for="(item,index) in menuData" :key="index">              
            <router-link :to="item.url">{{item.name}}</router-link>
          </li>          
        </ul>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>    
</template>

<script>
import bus from '@/utils/eventBus.js'
export default {
  name: 'header',
  data () {
    return {
      // webui: null
      // 是否登录 
      isUserLogin: false,
      // 当前用户名
      uname: '',
      menuData: [],
      keySearch: '',
      selectSearch: '1'
    }
  },
  mounted () {    
    // 获取本地信息 用户登录成功后取用户名
    const ui = this.$api.ui
    if (ui == null) {
      this.isUserLogin = false
      this.uname = ''
      //监听广播 用户登录成功后接收用户名
      bus.on('userLoginEvent',(data) => {
        // console.log('userLoginEvent=', data)
        if (data !== '') {
          this.isUserLogin = true
          this.uname = data
        } else {
          this.isUserLogin = false
          this.uname = ''
        }      
      })
    } else {
      this.isUserLogin = true
      this.uname = ui.uname      
    }
    this.getMenuData()   
  },
  methods: {
    async getMenuData () {
      const params = '?type=0&size=10' 
      const { data: ret } = await this.$http.get(this.$api.menulist + params)     
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.menuData = ret.data.records
      }
    },
    search () {
      // console.log('selectSearch=', this.selectSearch)
      console.log('keySearch=', this.keySearch)
      if (this.selectSearch === '1') {
        this.$router.push({
          path: '/resource/index',
          query: {
            key: encodeURIComponent(this.keySearch)
          }
        })
      } else if (this.selectSearch === '2' && this.keySearch !== '') {
        this.$router.push({
          path: '/news/index',
          query: {
            key: encodeURIComponent(this.keySearch)
          }
        })
      }
    },
    async logout () {
      this.$confirm('您确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          //background: 'rgba(0, 0, 0, 0.7)'
        })
        const { data: ret } = await this.$http.post(this.$api.logout)
        // console.log('ret=', ret)
        if (ret.code === 1) {
          this.isUserLogin = false
          this.uname = ''
          this.$message.success(ret.msg)
          this.$storage.remove('dxkjy-webui')
          this.$router.replace('/')
        } else {
          this.$message.error(ret.msg)
        }
        loading.close()
      })
    }    
  }  
}
</script>

<style scoped>

</style>