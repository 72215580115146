<!--web站点布局页面-->
<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>  
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'home',
  components: {
    Header,
    Footer
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
